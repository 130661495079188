<template>
  <div class="hotEventScatter">
    <!-- <h3 style="color: #333333">TOP10公司专利趋势</h3> -->
    <div class="myScatter" ref="myScatter"></div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "HotEventScatter",
  props: {
    apiDataScatterChart: Object,
  },
  data() {
    return {
      myScatter: "",
      scatterData: []
    };
  },
  watch: {
    apiDataScatterChart: {
      handler(newVal, oldVal) {
        this.scatterData = []
        console.log("newVal", newVal);
        newVal.forEach(i => {
          let item = [i.release_time, i.hotness, i.event]
          this.scatterData.push(item)
        })
        this.setOriginScatter();
        this.setScatter();
      },
      deep: true,
    },
  },
  // mounted() {
  //   this.setOriginScatter();
  //   this.setScatter();
  // },
  methods: {
    // 柱状图初始化
    setOriginScatter() {
      echarts.init(this.$refs.myScatter).dispose();
      this.myScatter = echarts.init(this.$refs.myScatter);
    },
    // 设置柱状图
    setScatter() {
      let option = {
        xAxis: {
          type: "category",
          // name: "时间轴",
        },
        yAxis: {},
        series: [
          {
            symbolSize: 20,
            data: this.scatterData,
            type: "scatter",
          },
        ],
        tooltip: {
          position: "right",
          formatter: function(params) {
            return `● 时间：${params.data[0]} <br> ● 热点事件：${params.data[2]} <br> ● 热度： ${params.data[1]}`
          }
        }
      };
      this.myScatter.setOption(option)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.hotEventScatter {
  width: 100%;
  margin-left: 0px;
  margin-top: 5px;
  background-color: white;
  padding-left: 20px;
  border-radius: 6px;
  padding-top: 10px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  .myScatter {
    min-height: 400px;
    // width: 50%
  }
}
</style>
