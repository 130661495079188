<template>
  <div class="hotNewsListWrapper" id="hotNewsListWrapper">
    <div class="newsList" v-loading.fullscreen.lock="changeLoading">
      <div class="newsItemCrew">
        <div
          class="newsListSourceItem"
          v-for="(i, index) in apiNewsList"
          :key="i.event_id + index.toString()"
        >
        <el-card>
          <div class="newsListSourceItem-name">
            <div class="newsTitle">
              <div style="display: flex">
                <!-- <Star
                  class="newsList-star"
                  :apiData="i.main_news"
                  :type="'news'"
                  :isFavorites="isFavorites"
                  @cancelStar="handleCancelStar"
                ></Star> -->
                <p
                  v-if="hasKeyword"
                  ref="newsTitleP"
                  class="newsTitle-p"
                  v-html="highlight(i.title_faiss, hasKeyword)"
                  @click="showDetail(i)"
                ></p>
                <p
                  v-if="!hasKeyword"
                  ref="newsTitleP"
                  class="newsTitle-p"
                  style="font-weight: bold;font-size: 16px;color: #333333"
                  @click="showDetail(i.main_news)"
                  v-html="esHL(i.main_news.title)"
                ></p>
                <img
                  src="@/assets/images/suanfa.png"
                  style="width: 15px; height: 15px; margin-left: 10px"
                  v-if="
                    env != 'pro' &&
                    (i.main_news.matching_type == 2 ||
                      i.main_news.matching_type == 3)
                  "
                />
                <img
                  src="@/assets/images/guize.png"
                  style="width: 15px; height: 15px; margin-left: 10px"
                  v-if="
                    env != 'pro' &&
                    (i.main_news.matching_type == 1 ||
                      i.main_news.matching_type == 3)
                  "
                />
                <!-- <Star :apiData="i" :type="'news'" :isFavorites="isFavorites" @cancelStar="handleCancelStar"></Star> -->
              </div>
              <div style="display: flex; margin-bottom: 5px">
                <p
                  v-if="i.main_news.polarity !== ''"
                  :class="
                    i.main_news.polarity == 1
                      ? 'newsTitle-polarity'
                      : 'newsTitle-polarityBad'
                  "
                >
                  {{ i.main_news.polarity == 1 ? "正面" : "负面" }}
                </p>
              </div>
            </div>
            <p style="margin-left: 10px">
              <el-tag
                style="margin-right: 5px"
                :effect="company.extract_type == 1 ? 'dark' : 'plain'"
                size="mini"
                :type="company.is_hot ? 'danger' : 'success'"
                v-for="company in i.main_news.match_rule"
                :key="company.short_name"
                :style="company.short_name == '...' ? 'cursor: pointer' : ''"
                @click="handleTag(i, company.short_name, 'match_rule')"
                >{{ company.short_name }}</el-tag
              >
            </p>
            <p style="margin-left: 10px" v-if="env != 'pro'">
              <el-tag
                style="margin-right: 5px"
                size="mini"
                type="warning"
                effect="plain"
                v-for="company in i.main_news.match_algo"
                :key="company.short_name"
                :style="company.short_name == '...' ? 'cursor: pointer' : ''"
                @click="handleTag(i, company.short_name, 'match_algo')"
                >{{ company.short_name }}</el-tag
              >
            </p>
            <p
              style="margin-left: 10px; margin-top: 2px"
              v-if="env != 'pro' && i.main_news.score"
            >
              <el-tag size="mini" type="primary" effect="dark"
                >评分：{{ i.main_news.score }}</el-tag
              >
            </p>
          </div>
          <span class="newsListSourceItem-time">{{
            i.main_news.release_time
          }}</span>
          <span v-if="i.main_news.source" class="newsListSourceItem-source">{{
            i.main_news.source == ""
              ? "未知"
              : i.main_news.source | webSourceFilter
          }}</span>
          <span v-else class="newsListSourceItem-source">{{
            i.main_news.web_source | webSourceFilter
          }}</span>
          <!-- <span class="newsListSourceItem-tagBlue" v-if="i.main_news.event_classify">{{
          i.main_news.event_classify
        }}</span> -->
          <span
            v-for="(arr, idx) in i.main_news.title_classify_array"
            :key="arr + idx.toString()"
          >
            <!-- <span class="newsListSourceItem-tagBlue" v-if="arr == 1">{{
            type_map[idx]
          }}</span> -->
            <el-tag
              style="margin-left: 5px; margin-bottom: 5px"
              type="primary"
              size="mini"
              v-if="arr == 1"
              >{{ type_map[idx] }}</el-tag
            >
          </span>
          <span
            v-for="(tag, index) in i.main_news.title_classify_tags"
            :key="tag + index.toString()"
          >
            <!-- <span class="newsListSourceItem-tag2" v-if="index%2==0 && tag">{{
            tag
          }}</span> -->
            <el-tag
              style="margin-left: 5px; margin-bottom: 5px"
              type="warning"
              size="mini"
              v-if="index % 2 == 0 && tag"
            >
              {{ tag }}
            </el-tag>
            <!-- <span class="newsListSourceItem-tagRed" v-if="index%2!=0 && tag">{{
            tag
          }}</span> -->
            <el-tag
              style="margin-left: 5px; margin-bottom: 5px"
              type="danger"
              size="mini"
              v-if="env != 'pro' && index % 2 != 0 && tag"
            >
              {{ tag }}
            </el-tag>
          </span>
          <div
            style="margin-left: 10px; margin-right: 10px; float: right"
            v-if="showRadio"
            @change="changeNewsRight(i.main_news.industry_is_right, i)"
          >
            <el-radio v-model="i.main_news.industry_is_right" :label="1"
              >正确</el-radio
            >
            <el-radio v-model="i.main_news.industry_is_right" :label="0"
              >错误</el-radio
            >
          </div>
          <!-- 子新闻 -->
          <NewsListHotSub
            :apiDataNewsList="i.news_list"
          >
          </NewsListHotSub>
          
        </el-card>
        </div>
      </div>
      <!-- <div class="contentFooter">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="requestParams.page"
        :page-size="requestParams.page_size"
        :total="pageTotal"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div> -->
      <el-dialog
        class="newsDetail"
        title="新闻详情"
        :visible.sync="newsDetailVisible"
        width="60%"
        top="5vh"
        destroy-on-close
        append-to-body
      >
        <div
          class="newsDetail-content"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-target=".newsDetail"
        >
          <div class="newsDetail-content-header">
            <p class="newsDetailTitle" v-html="esHL(newsDetail.title_true)"></p>
            <div class="des">
              <span class="time">{{ newsDetail.release_time }}</span>
              <span class="source" v-if="newsDetail.source">{{
                newsDetail.source
              }}</span>
              <span class="source" v-else>{{ newsDetail.web_source }}</span>
            </div>
          </div>
          <div
            class="newsDetail-content-text"
            v-html="newsDetail.content"
          ></div>
          <div class="btnBox">
            <el-button type="primary" @click="toOriginPage"
              >前往原网页</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        width="30%"
        title="修改一级分类"
        :visible.sync="showChangeClass"
      >
        <el-radio-group
          v-model="newsClass"
          style="display: flex; flex-direction: column"
        >
          <el-radio :label="0" style="margin-bottom: 10px">人员</el-radio>
          <el-radio :label="1" style="margin-bottom: 10px">产品技术</el-radio>
          <el-radio :label="2" style="margin-bottom: 10px">资金</el-radio>
          <el-radio :label="3" style="margin-bottom: 10px">经营</el-radio>
          <el-radio :label="4" style="margin-bottom: 10px">法律法规</el-radio>
        </el-radio-group>
        <div style="display: flex; flex-direction: row-reverse">
          <el-button type="primary" size="small" @click="changeNewsClass"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- <div class="showMore" @click="showMore">
    <p>{{ showMoreText }}</p>
  </div> -->
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 15px 0 15px 0;
      "
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="page"
        :total="total"
        :page-size="page_size"
        :page-count="5"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import { EventBus } from "../utils/eventBus.js";
import cookie from "@/utils/common.js";
import utils from "@/utils/utils.js";
import Star from "@/components/Star.vue";
import NewsListHotSub from "@/components/NewsListHotSub.vue"

export default {
  name: "NewsList",
  props: {
    apiDataNewsList: Array,
    showRadio: Boolean,
    hasKeyword: {
      type: [String, Boolean],
    },
    isFavorites: Boolean, // 判断该新闻列表组件是否用于收藏夹
    page: Number,
    page_size: Number,
    total: Number,
  },
  components: {
    Star,
    NewsListHotSub
  },
  watch: {
    //   newsShowLine: {
    //     handler(newValue) {
    //       let style_text = `#newsTitle:nth-of-type(${newValue}) {display: inline !important; }`
    //       this.addCSS(style_text)
    //     }
    //   }
    apiDataNewsList: {
      handler(newValue) {
        console.log("HotNewsLIst", newValue);
        let newValueTemp = JSON.parse(JSON.stringify(newValue));
        utils.scrollToTopByClass("hotNewsListWrapper"); // 滚动回顶部
        console.log("滚动回顶部");
        if (newValue.length == 0) {
          console.log("newValueTemp is empty");
          this.apiNewsList = [];
          return;
        }
        this.apiNewsList = [];
        newValueTemp.forEach((n) => {
          n.main_news.match_rule = []; // 规则匹配到的公司
          n.main_news.match_algo = []; // 算法匹配到的公司
          n.main_news.match_rule_total = []; // 规则匹配到的公司
          n.main_news.match_algo_total = []; // 算法匹配到的公司
          let company_list = [];
          n.main_news.company_list.forEach((c) => {
            company_list.push(c);
          });
          n.main_news.company_list_arr_total = this.unique(
            company_list,
            "short_name"
          );
          n.main_news.company_list_arr =
            n.main_news.company_list_arr_total.slice(0, 10);
          if (n.main_news.company_list_arr_total.length > 10) {
            n.main_news.company_list_arr.push({
              short_name: "...",
            });
          }
          n.main_news.company_list_arr_total.forEach((c) => {
            if (!c.matching_type || c.matching_type == 1) {
              if (c.short_name != '') {
                n.main_news.match_rule_total.push(c);
              }
            } else if (c.matching_type == 2 || c.matching_type == 3) {
              if (c.short_name != '') {
                n.main_news.match_algo_total.push(c);
              }
            }
          });
          n.main_news.title_classify_tags.forEach((t, index) => {
            if (
              index > 0 &&
              index % 2 == 0 &&
              n.main_news.title_classify_tags[index] ==
                n.main_news.title_classify_tags[index - 2]
            ) {
              n.main_news.title_classify_tags[index] = "";
            }
            if (
              index % 2 != 0 &&
              n.main_news.title_classify_tags[index] ==
                n.main_news.title_classify_tags[index - 1]
            ) {
              n.main_news.title_classify_tags[index] = "";
            }
          });
          // 反爬
          // 生成三条乱码混淆新闻
          n.main_news.title_arr = [];
          for (let m = 0; m < 3; m++) {
            n.main_news.title_arr.push(
              `<span id="newsTitle">${utils.randomChineseString(6)}</span>`
            );
          }
          // 将第newsShowLine项替换成真实新闻
          this.newsShowLine = sessionStorage.getItem("newsShowLine");
          console.log("session_newsShowLine", this.newsShowLine);
          n.main_news.title_arr.splice(
            this.newsShowLine - 1,
            1,
            `<span id="newsTitle">  ${n.main_news.title}</span>`
          );
          n.main_news.title = "";
          n.main_news.title_arr.forEach((t) => {
            n.main_news.title += t;
          });
          n.main_news.match_rule = n.main_news.match_rule_total.slice(0, 10);
          if (n.main_news.match_rule_total.length > 10) {
            n.main_news.match_rule.push({
              short_name: "...",
            });
          }
          n.main_news.match_algo = n.main_news.match_algo_total.slice(0, 10);
          if (n.main_news.match_algo_total.length > 10) {
            n.main_news.match_algo.push({
              short_name: "...",
            });
          }
          this.apiNewsList.push(n);
        });
        console.log("apiNewsList", this.apiNewsList);
        // this.apiNewsList = JSON.parse(JSON.stringify(newValue))
      },
      deep: true,
    },
  },
  data() {
    return {
      newsShowLine: 0,
      showMoreText: "加载更多",
      testRate: 0,
      apiNewsList: [],
      newsType: "",
      polarityType: "",
      is_master: false,
      newsDetailVisible: false,
      // newsSearchCompany: "",
      changeLoading: false,
      requestParams: {
        page: 1,
        page_size: 10,
        keyword: "",
        source_type: "0",
        company: "",
      },
      type_map: ["人员", "产品技术", "资金", "经营", "法律法规"],
      newsDetail: {
        title: "",
        release_time: "",
        source: "",
        content: "",
        url: "",
        web_source: "",
      },
      newsClass: 0,
      showChangeClass: false,
      newsIdSelected: "",
      newsList: [
        // {
        //   title: "比亚迪发布新一代ev：汉",
        //   polarity: 1,
        //   release_time: "2020-04-28",
        //   source: "baidu",
        //   web_source: "36kr",
        //   event_classify: "产品新闻",
        //   company_opinion_list: [
        //     {
        //       company_name: "比亚迪",
        //       positive_score: "10",
        //       negative_score: "1"
        //     }
        //   ]
        // }
      ],
    };
  },
  methods: {
    // 分页器变化
    handlePageChange(val) {
      this.$emit("changePage", val);
    },
    // 响应取消收藏
    handleCancelStar(val) {
      this.$emit("cancelStar", 1);
      console.log("响应取消收藏", val);
      // utils.objArrRemove(this.apiNewsList, "id", val)
    },
    // 加载更多
    showMore() {
      this.$emit("showMore", 1);
    },
    // 修改es的highlight默认样式
    esHL(text) {
      if (!text) return; // 一开始text为空，会报错
      // console.log("text", text)
      text = text.replace(/<em>/g, '<font style="color:red;">');
      text = text.replace(/<\/em>/g, "</font>");
      // console.log("text", text)
      return text;
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // 动态添加css
    addCSS(style_text) {
      let s = document.createElement("style");
      s.innerText = style_text;
      document.head.appendChild(s);
    },
    // 响应点击tag
    handleTag(i, short_name, type) {
      console.log("type", type);
      if (short_name == "..." && type == "match_rule") {
        // i.company_list_arr = i.company_list_arr_total
        i.match_rule = i.match_rule_total;
        console.log(i.match_rule_total);
        console.log(i.match_rule);
      } else if (short_name == "..." && type == "match_algo") {
        i.match_algo = i.match_algo_total;
      }
    },
    handleTagHot(i, short_name) {
      if (short_name == "...") {
        i.company_is_hot = i.company_is_hot_total;
      }
    },
    // 点击修改分类
    handleChangeNewsClass(news) {
      this.showChangeClass = true;
      this.newsIdSelected = news.id;
      this.newsClass = "";
      console.log("选择新闻id", this.newsIdSelected);
    },
    // 修改新闻一级分类
    changeNewsClass() {
      console.log(this.newsClass);
      let params = {
        tag_type: "title_classify_array__change",
        tag_value: "",
        news_id: this.newsIdSelected,
      };
      switch (this.newsClass) {
        case 0:
          params.tag_value = '["1", "0", "0", "0", "0"]';
          break;
        case 1:
          params.tag_value = '["0", "1", "0", "0", "0"]';
          break;
        case 2:
          params.tag_value = '["0", "0", "1", "0", "0"]';
          break;
        case 3:
          params.tag_value = '["0", "0", "0", "1", "0"]';
          break;
        case 4:
          params.tag_value = '["0", "0", "0", "0", "1"]';
          break;
        default:
          params.tag_value = '["1", "0", "0", "0", "0"]';
          break;
      }
      console.log(params);
      this.changeLoading = true;
      api.changeNewsTag(params).then((res) => {
        if (res.status == 200) {
          this.apiDataNewsList.forEach((i, index) => {
            if (i.id == this.newsIdSelected) {
              this.apiDataNewsList[index].title_classify_array = JSON.parse(
                params.tag_value
              );
              console.log("命中", JSON.parse(params.tag_value));
            }
          });
          this.showChangeClass = false;
          this.changeLoading = false;
          this.$message.success("修改新闻一级分类成功！");
        } else if (res.status == 201) {
          this.apiDataNewsList.forEach((i, index) => {
            if (i.id == this.newsIdSelected) {
              this.apiDataNewsList[index].title_classify_array = JSON.parse(
                params.tag_value
              );
              console.log("命中", JSON.parse(params.tag_value));
            }
          });
          this.showChangeClass = false;
          this.changeLoading = false;
          this.$message.warning(res.msg);
        }
      });
    },
    // 删除数组的某元素
    arrRemove(arr, val) {
      let index = this.getArrIndex(arr, val);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    // 删除(修改)标签
    changeNewsTag(value, type, news) {
      let tag_type_tmp = "";
      if (type == 0) {
        tag_type_tmp = "title_classify_array";
      } else if (type == 1) {
        tag_type_tmp = "title_classify_tags";
      }
      let params = {
        tag_type: tag_type_tmp,
        tag_value: value,
        news_id: news.id,
      };
      console.log("删除标签参数", params);
      this.$confirm("确定删除该标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .changeNewsTag(params)
            .then((res) => {
              if (res.status == 200) {
                // this.arrRemove(news[tag_type_tmp], value)
                if (type == 0) {
                  news.title_classify_array = ["0", "0", "0", "0", "0"];
                } else if (type == 1) {
                  news.title_classify_tags.forEach((i, index) => {
                    if (i == value) {
                      console.log(value);
                      news.title_classify_tags[index] = "";
                    }
                  });
                  news.title_classify_tags = JSON.parse(
                    JSON.stringify(news.title_classify_tags)
                  );
                  console.log(news.title_classify_tags);
                }
                this.$message.success(res.msg);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.error(err);
              this.$message.error("删除失败，请重试！");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 关键词红色高亮
    highlight(text, keyword) {
      console.log("text", text);
      if (text.indexOf(keyword) != -1) {
        let text_arr = text.split(keyword);
        text =
          "<p>▪ " +
          text_arr[0] +
          `<span style="color:red;">${keyword}</span>` +
          text_arr[1] +
          "</p>";
        return text;
      } else {
        return "<p>▪ " + text + "</p>";
      }
    },
    // 删除新闻
    removeNews(title, index) {
      console.log("title", title);
      let params = {
        news_title: title,
      };
      this.$confirm("此操作将删除该新闻, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("index", index);
          this.apiDataNewsList.splice(index, 1);
          api.removeNews(params).then((res) => {
            if (res.status == 200) {
              this.$message.success("删除成功");
              // EventBus.$emit("remove_news", 1)
            } else {
              this.$message.warning(res.msg);
              // setTimeout(() => {
              //   EventBus.$emit("remove_news", 1)
              // }, 1000)
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改新闻产品判断
    changeNewsRight(val, i) {
      let params = {
        news_id: i.id,
        industry_is_right: i.industry_is_right,
      };
      console.log("修改新闻产品判断val", val);
      console.log("修改新闻产品判断", params);
      this.$confirm("您确定要修改该新闻的产品判断吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .changeNewsRight(params)
            .then((res) => {
              if (res.status == 200) {
                this.$message.success("修改成功");
                // EventBus.$emit("change_polarity", 1)
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: "接口出错，请重试或联系管理员",
                type: "error",
              });
            });
        })
        .catch(() => {
          i.industry_is_right = null;
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    // 修改新闻极性
    changeNewsPolarity(i) {
      // 若新闻无极性，修改为正面
      let polarity = i.polarity ? (i.polarity == 1 ? 0 : 1) : 1;
      let params = {
        news_title: i.title,
        polarity: polarity,
      };
      console.log("i.polarity", i.polarity);
      this.$confirm(
        "您确定要修改该新闻极性吗?（备注: 若该新闻无极性，修改为正面）",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          i.polarity = i.polarity == 1 ? 0 : 1;
          api.changeNewsPolarity(params).then((res) => {
            if (res.status == 200) {
              this.$message.success("修改成功");
              // EventBus.$emit("change_polarity", 1)
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    // 监听极性统计点击
    listenPolarity() {
      EventBus.$on("polarity", (data) => {
        this.polarityType = data;
      });
    },
    // 监听事件分类统计点击
    listenNewsFilter() {
      EventBus.$on("news_filter", (data) => {
        this.newsType = data;
      });
    },
    // 监听company_id改变
    listenCompanyId() {
      EventBus.$on("company_id", (data) => {
        this.newsType = "";
        this.polarityType = "";
      });
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        this.newsType = "";
        this.polarityType = "";
      });
    },
    // 重置筛选
    reset() {
      EventBus.$emit("resetSelect", 1);
      // EventBus.$emit("news_filter", "")
      this.newsType = "";
      this.polarityType = "";
    },
    // 前往原网页
    toOriginPage() {
      window.open(this.newsDetail.url, "_blank");
    },
    // 显示详情
    showDetail(info) {
      console.log(info);
      if (info.content) {
        this.newsDetail = info;
        console.log("newsShowLine", this.newsShowLine);
        if (!this.hasKeyword) {
          // 没有关键词的高亮（有highlight）
          this.newsDetail.title_true = info.title
            .split("</span>")
            [this.newsShowLine - 1].replace('<span id="newsTitle">', "");
        } else {
          // 有关键词的高亮
          this.newsDetail.title_true = info.title_faiss;
        }

        this.newsDetailVisible = true;
      } else {
        window.open(info.url, "_blank");
      }
    },
  },
  mounted() {
    this.listenNewsFilter();
    this.listenReset();
    this.listenCompanyId();
    this.listenPolarity();
    this.env = process.env.VUE_APP_CURRENTMODE;
    this.is_master =
      cookie.getCookie("module").indexOf("is_master") != -1 ? true : false;
    console.log("is_master", this.is_master);
  },
  beforeDestroy() {
    EventBus.$off("news_filter");
    EventBus.$off("polarity");
    EventBus.$off("company_id");
    EventBus.$off("reset");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.hotNewsListWrapper {
  overflow-y: scroll;
  height: 100%;
  .newsList {
    font-size: 17px;
    margin-top: 5px !important;
    // margin-left: 10px;
    width: 100%;
    border-radius: 7px;
    .searchParam {
      display: flex;
      align-items: center;
      padding-right: 10px;
      font-size: 14px;
      font-weight: normal;
      color: #409eff;
      p {
        margin-right: 10px;
      }
    }
    &-star {
      margin-right: 5px;
    }
    .newsItemCrew {
      // overflow-y: scroll;
      // max-height: 70vh !important;
      padding-top: 10px;
    }
    .newsListSourceItem {
      font-size: 16px;
      padding-left: 15px;
      margin-bottom: 10px;
      // padding-bottom: 10px;
      border-bottom: 1px solid #f5f4f9;
      // &-name {
      //   display: flex;
      //   justify-content: space-between;
      // }
      &-tag,
      &-tag2,
      &-tagBlue,
      &-tagRed {
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 5px;
        font-size: 12px;
        padding: 1px 3px 1px 3px;
        background-color: #f0f9eb;
        border-color: #e1f3d8;
        color: #67c23a;
        border-radius: 4px;
        border: 1px solid #d9ecff;
      }
      &-tag2 {
        background-color: #fdf6ec;
        border-color: #faecd8;
        color: #e6a23c;
      }
      &-tagBlue {
        background-color: #ecf5ff;
        border-color: #d9ecff;
        color: #409eff;
      }
      &-tagRed {
        background-color: #fef0f0;
        border-color: #fde2e2;
        color: #f56c6c;
      }
      &-time,
      &-source,
      &-webSource {
        display: inline-block;
        font-size: 12px;
        color: #999;
        margin-top: 10px;
        margin-left: 10px;
      }
      &-source {
        margin-left: 10px;
      }
      &-webSource {
        margin-left: 20px;
      }
      &-website {
        color: rgb(49, 135, 216);
        font-size: 14px;
        margin-top: 5px;
        word-break: break-all;
        margin-right: 10px;
      }
    }
    .companyTag {
      border-radius: 4px;
      margin-top: 5px;
      margin-right: 8px;
      margin-bottom: 5px;
      display: inline-block;
      padding: 3px 6px 3px 6px;
      background-color: #3e43f4;
      color: white;
    }
    .newsTitle {
      &-p {
        cursor: pointer;
        margin-left: 10px;
      }
    }
    .newsDetail {
      .btnBox {
        display: flex;
        margin-top: 30px;
        width: 100%;
        justify-content: center;
      }
      &-content {
        width: 100%;
        // margin-left: 0%;
        // margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 20px;
        min-height: 40vh;
        background: #fff;
        -webkit-box-shadow: 0 1px 10px 0 hsla(0, 0%, 63.9%, 0.5);
        box-shadow: 0 1px 10px 0 hsla(0, 0%, 63.9%, 0.5);
        border-radius: 7px;
        &-text {
          margin-left: 60px;
          margin-right: 10px;
          margin-top: 40px;
          max-height: 50vh;
          overflow-y: scroll;
          padding-right: 50px;
          .basicTitle {
            font-size: 16px;
            font-weight: bold;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .basicInfo {
            margin-top: 5px;
            margin-left: 10px;
          }
        }
        &-header {
          background: #34465a;
          border-radius: 7px 7px 0 0;
          width: 100%;
          // padding: 0 60px;
          min-height: 70px;
          color: white;
          .newsDetailTitle {
            margin-left: 60px;
            padding-top: 10px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1px;
          }
          .des {
            margin-left: 60px;
            margin-top: 5px;
          }
          span {
            display: inline-block;
            margin-top: 8px;
            margin-right: 12px;
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
    .newsTitle,
    .listedTitle {
      display: flex;
      justify-content: space-between;
      // padding-right: 10px;
      width: 95%;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow:ellipsis;
      &-polarity,
      &-polarityBad {
        opacity: 0.9;
        height: 20px;
        min-width: 32px;
        margin-left: 10px;
        // margin-right: 10px;
        background-color: green;
        color: white;
        font-size: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-polarityBad {
        background-color: rgb(201, 37, 37);
      }
    }
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
}
</style>
