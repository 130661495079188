var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hotNewsListSubWrapper",attrs:{"id":"hotNewsListSubWrapper"}},[_vm._l((_vm.apiNewsList),function(i,index){return _c('div',{key:i.title + index.toString(),staticClass:"newsListSubSourceItem",staticStyle:{"margin-top":"5px","padding-left":"10px"}},[_c('div',{staticClass:"newsListSubSourceItem-name"},[_c('div',{staticClass:"newsTitle"},[_c('div',{staticClass:"newTitleLeftBox",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"color":"#1A73E8"}},[_vm._v("▪")]),(_vm.hasKeyword)?_c('p',{ref:"newsTitleP",refInFor:true,staticClass:"newsTitle-p",domProps:{"innerHTML":_vm._s(_vm.highlight(i.title_faiss, _vm.hasKeyword))},on:{"click":function($event){return _vm.showDetail(i)}}}):_vm._e(),(!_vm.hasKeyword)?_c('p',{ref:"newsTitleP",refInFor:true,staticClass:"newsTitle-p",domProps:{"innerHTML":_vm._s(_vm.esHL(i.title))},on:{"click":function($event){return _vm.showDetail(i)}}}):_vm._e(),(
                    _vm.env != 'pro' &&
                    (i.matching_type == 2 ||
                      i.matching_type == 3)
                  )?_c('img',{staticStyle:{"width":"15px","height":"15px","margin-left":"10px"},attrs:{"src":require("@/assets/images/suanfa.png")}}):_vm._e(),(
                    _vm.env != 'pro' &&
                    (i.matching_type == 1 ||
                      i.matching_type == 3)
                  )?_c('img',{staticStyle:{"width":"15px","height":"15px","margin-left":"10px"},attrs:{"src":require("@/assets/images/guize.png")}}):_vm._e(),(
                    _vm.env != 'pro' &&
                    (i.matching_type == 1)
                  )?_c('img',{staticStyle:{"width":"15px","height":"15px","margin-left":"10px"},attrs:{"src":require("@/assets/images/guize.png")}}):_vm._e()]),_c('div',{staticClass:"newsTitleRightBox",staticStyle:{"display":"flex","justify-content":"flex-start"}},[(i.source)?_c('span',{staticClass:"newsListSubSourceItem-source",staticStyle:{"min-width":"60px"}},[_vm._v(_vm._s(_vm._f("webSourceFilter")(i.source == "" ? "未知" : i.source)))]):_c('span',{staticClass:"newsListSubSourceItem-source",staticStyle:{"min-width":"60px"}},[_vm._v(_vm._s(_vm._f("webSourceFilter")(i.web_source)))]),_c('span',{staticClass:"newsListSubSourceItem-time",staticStyle:{"min-width":"94.75px","text-align":"end"}},[_vm._v(_vm._s(_vm._f("formatDate")(i.release_time)))])])])])])}),(_vm.newsDetailVisible)?_c('NewsDetail',{attrs:{"visible":_vm.newsDetailVisible,"info":_vm.newsDetail},on:{"changeNewsDetailVisible":_vm.handleNewsDetailVisible}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }