<template>
  <div class="eventList">
    <div class="title" style="justify-content: space-between;padding-right:5px">
      <p id="zjxw">核心事件</p>
    </div>
    <div class="newsItemCrew">
      <div
        class="eventListSourceItem"
        v-for="(i, index) in eventList"
        :key="i.title + index.toString()"
        @click="toInfo(i)"
      >
        <div class="eventListSourceItem-name">
          <div class="newsTitle">
            <p ref="newsTitleP" class="newsTitle-p">▪ {{ i.title }}</p>
            <p
              :class="
                i.polarity == 1 ? 'newsTitle-polarity' : 'newsTitle-polarityBad'
              "
            >
              {{ i.polarity == 1 ? "正面" : "负面" }}
            </p>
          </div>
        </div>
        <span class="eventListSourceItem-time">{{ i.release_time }}</span>
        <span class="eventListSourceItem-source">{{
          i.source == "" ? "未知" : i.source
        }}</span>
        <span class="eventListSourceItem-tagBlue" v-if="i.event_classify">{{
          i.event_classify
        }}</span>
      </div>
    </div>
    <!-- <div class="contentFooter">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="requestParams.page"
        :page-size="requestParams.page_size"
        :total="pageTotal"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "eventList",
  props: {
    apiDataLineChart: Array,
  },
  watch: {},
  data() {
    return {
      newsSearchCompany: "",
      requestParams: {
        page: 1,
        page_size: 10,
        keyword: "",
        source_type: "0",
        company: ""
      },
      eventList: [
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
        {
          title: "xxxxx事件xxxxxxxxxxx",
          polarity: 1,
          release_time: "2020-05-02",
          source: "baidu",
          web_source: "bb",
          event_classify: "合作"
        },
      ]
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.eventList {
  font-size: 17px;
  margin-top: 5px;
  // margin-left: 10px;
  width: 98%;
  border-radius: 7px;
  .searchParam {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #409eff;
    p {
      margin-right: 10px;
    }
  }
  .newsItemCrew {
    overflow-y: scroll;
    max-height: 72vh !important;
  }
    .eventListSourceItem {
    cursor: pointer;
    font-size: 16px;
    padding-left: 15px;
    margin-bottom: 10px;
    // padding-bottom: 10px;
    border-bottom: 1px solid #f5f4f9;
    // &-name {
    //   display: flex;
    //   justify-content: space-between;
    // }
    &-tag,
    &-tag2,
    &-tagBlue,
    &-tagRed {
      display:inline-block;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 12px;
      padding: 1px 3px 1px 3px;
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      color: #67c23a;
      border-radius: 4px;
      border: 1px solid #d9ecff;
    }
    &-tag2 {
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
    }
    &-tagBlue {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      color: #409eff;
    }
    &-tagRed {
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f56c6c;
    }
    &-time,
    &-source,
    &-webSource {
      display: inline-block;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      margin-left: 10px;
    }
    &-source {
      margin-left: 10px;
    }
    &-webSource {
      margin-left: 20px;
    }
    &-website {
      color: rgb(49, 135, 216);
      font-size: 14px;
      margin-top: 5px;
      word-break: break-all;
      margin-right: 10px;
    }
  }
}
</style>
