<template>
  <div class="newsEcharts">
    <div class="lineChart" ref="lineChartPatent"></div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js"

export default {
  name: "NewsEcharts",
  props: {
    apiDataLineChart: Object,
  },
  data() {
    return {
      lineChartPatent: "",
      monthLegend: [],
      monthData: [],
    };
  },
  watch: {
    apiDataLineChart: {
      deep: true,
      handler(newVal, oldVal) {
        this.monthLegend = []
        this.monthData = []
        // console.log("news Echarts", newVal)
        Object.keys(this.apiDataLineChart).forEach(i => {
          // console.log("i", i)
          this.monthLegend.push(i)
          this.monthData.push(this.apiDataLineChart[i])
        })
        this.setOriginLineChartPatent()
        this.setLineChartPatent()
      }
    }
  },
  methods: {
    // 专利折线图echarts初始化
    setOriginLineChartPatent() {
      echarts.init(this.$refs.lineChartPatent).dispose();
      this.lineChartPatent = echarts.init(this.$refs.lineChartPatent);
      let that = this;
      this.lineChartPatent.on("click", function (params) {
        console.log("click", params.name);
        EventBus.$emit('month', params.name)
        // that.year = params.name;
        // let params_patent = {
        //   company_id: sessionStorage.getItem("company_id"),
        //   page_size: 20,
        //   year: that.year,
        // };
        // api.getPatent(params_patent).then((res) => {
        //   console.log("patent", res);
        //   that.patentsList = res.data;
        // });
        // that.wordData =
        //   that.company_info_json[that.$route.query.company_name].data.keywords[
        //     that.year
        //   ];
        // that.wordData = that.wordCloudKeywords[that.year];
        // console.log("keywords", that.wordData);
        // that.setWordCloud();
      });
    },
    // 专利折线图渲染
    setLineChartPatent() {
      let option = {
        title: {
          text: "新闻热度统计",
          textStyle: {
            color: "#333333",
            fontWeight: "bold",
            fontSize: "18",
          },
        },
        itemStyle: {
          color: "#01a4d9",
          opacity: 0.8,
        },
        grid: {
          left: "8%",
          right: "5%",
          top: "15%",
          bottom: "5%",
          containLabel: true,
        },
        label: {
          show: true,
          color: "#333333"
        },
        textStyle: {
          color: "white",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{c}",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          axisLabel: {
            color: "#6c706f",
            interval: 0,
            rotate: 40,
          },
          type: "category",
          boundaryGap: false,
          data: this.monthLegend,
          // data: ["2016","2017", "2018", "2019", "2020"],
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            data: this.monthData,
            type: "line",
          },
        ],
      };
      this.lineChartPatent.setOption(option);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .newsEcharts {
    width: 100%;
    .lineChart {
      width: 100%;
      margin-left: 10px;
      margin-right: 5%;
      margin-top: 1vw;
      height: 220px;
      border: 1px solid rgb(191, 192, 197);
      border-radius: 4px;
    }
  }
</style>
