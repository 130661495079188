<template>
  <div
    class="industryNews"
    v-loading.fullscreen.lock="
      hotLoading | keywordLoading | typeLoading | polarityLoading
    "
  >
    <!-- <p class="industryNews-title">{{ current_node_name }}</p> -->
    <BtnSubscribe
      :subscribeNodeName="subscribeNodeName"
      :node_name_select="current_node_name"
      :domain_id_select="domain_id_select"
    ></BtnSubscribe>
    <div class="radioBox" style="margin: 10px 5px 5px 15px" >
      <el-radio-group
        v-model="tabRadio"
        size="small"
        @change="handleRadioChange"
      >
        <el-radio-button label="热点事件"></el-radio-button>
        <el-radio-button label="最新舆情"></el-radio-button>
      </el-radio-group>
    </div>

    <div
      class="industryNews-left"
      style="padding-top: 10px"
      v-if="tabRadio == '最新舆情'"
    >
      <!-- <p style="margin: 0 0 10px 20px; font-size: 17px; font-weight: bold">
        筛选条件
      </p> -->
      <el-form
        ref="form"
        :model="filterForm"
        label-width="90px"
        label-position="left"
      >
        <el-form-item label="企业" v-if="tabRadio == '最新舆情'">
          <div class="checkbox-company" ref="checkbox-company">
            <el-radio-group
              @change="handleCheckBox"
              v-model="companyNewsParams.company"
              size="mini"
            >
              <el-radio-button
                v-for="company in companys"
                :label="company.value"
                :key="company.value"
                style="margin-bottom: 8px"
                >{{ company.label }}</el-radio-button
              >
            </el-radio-group>
            <!-- <div class="checkbox-down" @click="showMoreCheckbox"><i class="el-icon-caret-bottom"></i></div> -->
            <el-button
              class="checkbox-down"
              @click="showMoreCheckbox"
              icon="el-icon-more"
              v-if="this.style_height == '30px' && this.companys.length >= 15"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="企业" v-else>
          <div class="checkbox-company" ref="checkbox-company">
            <el-radio-group
              v-model="hotEventCompany"
              size="mini"
              @change="handleRadioHotCompany"
            >
              <el-radio-button
                v-for="company in hotIndustryCompany"
                :label="company.value"
                :key="company.label"
                style="margin-bottom: 8px"
                >{{ company.label }}</el-radio-button
              >
            </el-radio-group>
            <!-- <div class="checkbox-down" @click="showMoreCheckbox"><i class="el-icon-caret-bottom"></i></div> -->
            <el-button
              class="checkbox-down"
              @click="showMoreCheckbox"
              icon="el-icon-more"
              v-if="this.style_height == '30px' && this.companys.length >= 15"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="事件类型" v-if="tabRadio == '最新舆情'">
          <el-radio-group
            v-model="companyNewsParams.news_type"
            size="mini"
            @change="handleEvent"
          >
            <el-radio-button
              v-for="event in events"
              :label="event.value"
              :key="event.value"
              >{{ event.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini">立即创建</el-button>
        </el-form-item> -->
        <el-form-item
          label="新闻类别"
          v-if="
            tabRadio == '最新舆情' && companyNewsParams.news_type != '司法公告'
          "
        >
          <el-radio-group
            v-model="filter_type"
            size="mini"
            @change="handleTypeChange"
          >
            <el-radio-button label="全部"></el-radio-button>
            <el-radio-button label="产品相关"></el-radio-button>
          </el-radio-group>
        </el-form-item>

        <!-- <el-radio-group
        v-model="filter_type"
        size="small"
        style="margin-left: 15px; margin-top: 15px"
        @change="handleTypeChange"
      >
        <el-radio-button label="全部"></el-radio-button>
        <el-radio-button label="产品相关"></el-radio-button>
      </el-radio-group> -->
      </el-form>
    </div>
    <div
      class="industryNews-left"
      v-loading="newsLoading"
      v-if="tabRadio == '最新舆情'"
    >
      <!--class="industryNews-left" v-loading="newsLoading" v-if="tabRadio == '最新舆情'-->

      <div style="height: 80vh">
        <div class="NewsListNewBox">
          <div class="NewsListNewLeftBox">
            <NewsListTwo
              :apiDataNewsList="newsList"
              :isEventRisk="companyNewsParams.news_type == '司法公告'"
              :newsShowLine="newsShowLine"
              :hasKeyword="false"
              :showRadio="(filter_type == '产品相关') & (env != 'pro')"
              :page="companyNewsParams.page"
              :page_size="companyNewsParams.page_size"
              :total="news_total"
              :newsLoading="newsLoading"
              @changePage="handleChangeNewsPage"
            ></NewsListTwo>
          </div>
          <div class="NewsListNewRightBox">
            <CompanyNews> </CompanyNews>
          </div>
        </div>

        <!-- <NewsListTwo
          :apiDataNewsList="newsList"
          :newsShowLine="newsShowLine"
          :hasKeyword="false"
          :showRadio="(filter_type == '产品相关') & (env != 'pro')"
          :page="companyNewsParams.page"
          :page_size="companyNewsParams.page_size"
          :total="news_total"
          @changePage="handleChangeNewsPage"
        ></NewsListTwo> -->
      </div>
    </div>
    <div v-if="tabRadio == '热点事件'" v-loading="hotNewsLoading">
      <!-- <HotEventScatter
        style="width: 800px"
        :apiDataScatterChart="apiDataScatterChart"
      ></HotEventScatter> -->
      <div class="NewsListHotBox">
        <div class="NewsListHotLeftBox" style="height: 80vh">
          <NewsListHotTwo
            :apiDataNewsList="newsListHot"
            :newsShowLine="newsShowLine"
            :hasKeyword="false"
            :page="hotNewsParams.page"
            :page_size="hotNewsParams.page_size"
            :total="hot_news_total"
            @changePage="handleChangeHotNewsPage"
          ></NewsListHotTwo>
        </div>
        <div class="NewsListHotRightBox">
          <CompanyNews> </CompanyNews>
          <!-- <NewsListHot
            :apiDataNewsList="newsListHot"
            :newsShowLine="newsShowLine"
            :hasKeyword="false"
            :page="hotNewsParams.page"
            :page_size="hotNewsParams.page_size"
            :total="hot_news_total"
            @changePage="handleChangeHotNewsPage"
          ></NewsListHot> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import utils from "@/utils/utils.js";
import { EventBus } from "../utils/eventBus.js";
import NewsList from "@/components/NewsList.vue";
import NewsListHot from "@/components/NewsListHot.vue";
import NewsListHotTwo from "@/components/NewsListHotTwo.vue";
import EventList from "@/components/EventList.vue";
import PolarityPie from "@/components/PolarityPie.vue";
import NewsTypeBar from "@/components/NewsTypeBar.vue";
import NewsKeywordCloud from "@/components/NewsKeywordCloud.vue";
import NewsEcharts from "@/components/NewsEcharts.vue";
import HotEventScatter from "@/components/HotEventScatter.vue";
import CompanyNews from "@/components/CompanyNews.vue";
import NewsListTwo from "@/components/NewsListTwo.vue";
import BtnSubscribe from "@/components/BtnSubscribe.vue";

export default {
  components: {
    NewsList,
    NewsListHot,
    EventList,
    PolarityPie,
    NewsTypeBar,
    NewsKeywordCloud,
    NewsEcharts,
    HotEventScatter,
    NewsListHotTwo,
    CompanyNews,
    NewsListTwo,
    BtnSubscribe,
  },
  data() {
    return {
      domain_id_select: 1,
      subscribeNodeName: [],
      current_node_name: "锂电池正极材料",
      loadFinish: false,
      hotNewsParams: {
        page: 1,
        page_size: 10,
        account: "",
        industry: "",
        company: "",
        node_id: 44,
      },
      hot_news_total: 0,
      news_total: 0,
      newsShowLine: 0,
      hotEventCompany: "",
      env: "",
      style_height: "30px",
      companyCheckBox: "",
      filter_type: "全部",
      apiDataScatterChart: [],
      filterForm: {
        company: [],
        eventType: [],
      },
      hotCompany: [],
      hotIndustryCompany: [],
      eventType: "",
      companys: [],
      // events: ["人员", "产品技术", "资金", "经营", "法律法规"],
      events: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "人员",
          value: "人员",
        },
        {
          label: "产品技术",
          value: "产品技术",
        },
        {
          label: "资金",
          value: "资金",
        },
        {
          label: "经营",
          value: "经营",
        },
        {
          label: "法律法规",
          value: "法律法规",
        },
        {
          label: "司法公告",
          value: "司法公告",
        },
      ],
      tabRadio: "热点事件",
      tabsValue: "1",
      isFirst: true,
      newsEchartsData: {},
      wordCloudData: [],
      imgList: [],
      loading: false,
      hotLoading: false,
      keywordLoading: false,
      typeLoading: false,
      polarityLoading: false,
      hotNewsLoading: false,
      newsLoading: false,
      company_id: "",
      showMoreText: "加载更多",
      newsType: "",
      polarityData: [],
      date: "",
      newsTypeData: {
        legend: [],
        data: [],
      },
      newsParams: {
        page: 1,
        page_size: 10,
        company: "",
        permissions: "",
        news_type: "",
        polarity: "",
        industry: "",
      },
      companyNewsParams: {
        page: 1,
        page_size: 10,
        account: "",
        // company_id: '',
        company: "",
        domain_id: "",
        industry: "",
        news_type: "",
      },
      newsList: [],
      newsListHot: [],
      hotEventParams: {
        company_id: 1045,
      },
    };
  },
  methods: {
    // 监听用户订阅更新
    listenUpdateSubscribe() {
      EventBus.$on("updateSubscribe", (data) => {
        this.subscribeNodeName = JSON.parse(
          sessionStorage.getItem("subscribeNodeName")
        );
      });
    },
    // 获取热点事件
    getHotNews() {
      this.hotNewsLoading = true;
      api.getHotNews(this.hotNewsParams).then((res) => {
        console.log("热点事件", res);
        this.newsListHot = res.data;
        this.hot_news_total = res.total;
        this.hotNewsLoading = false;
      });
    },
    // 热点事件翻页
    handleChangeHotNewsPage(val) {
      this.hotNewsParams.page = val;
      this.getHotNews();
    },
    // 新闻翻页
    handleChangeNewsPage(val) {
      this.companyNewsParams.page = val;
      if (this.companyNewsParams.news_type != "司法公告") {
        if (this.filter_type == "全部") {
          this.getCompanyNews();
        } else {
          this.getCompanyNewsByProduct();
        }
      } else {
        this.getEventRisk();
      }
    },
    getHotEvent() {
      api.getHotEvent(this.hotEventParams).then((res) => {
        console.log("getHotEvent", res);
        this.apiDataScatterChart = [];
        this.apiDataScatterChart = res.data;
        console.log("apiDataScatterChart", this.apiDataScatterChart);
      });
    },
    handleTypeChange(val) {
      console.log("切换类别", val);
      this.companyNewsParams.page = 1;
      this.newsList = [];
      if (val == "全部") {
        this.getCompanyNews();
      } else if (val == "产品相关") {
        this.getCompanyNewsByProduct();
      }
    },
    // 企业tab筛选
    handleCheckBox(val) {
      console.log("企业tab筛选", val);
      this.newsList = [];
      // this.companyNewsParams.company = val
      this.companyNewsParams.page = 1;
      if (this.companyNewsParams.news_type != "司法公告") {
        this.getCompanyNews();
      } else {
        this.getEventRisk();
      }
    },
    // 事件类型筛选
    handleEvent() {
      this.newsList = [];
      if (this.companyNewsParams.news_type != "司法公告") {
        this.companyNewsParams.page = 1;
        if (this.filter_type == "全部") {
          this.getCompanyNews();
        } else if (this.filter_type == "产品相关") {
          this.getCompanyNewsByProduct();
        }
      } else {
        console.log("司法公告");
        this.getEventRisk();
      }
    },
    // 获取司法公告
    getEventRisk() {
      this.newsLoading = true;
      api.getEventRisk(this.companyNewsParams).then((res) => {
        this.newsList = res.data;
        this.news_total = res.count;
        this.newsLoading = false;
      });
    },
    // 获取产业舆情(按产品)
    getCompanyNewsByProduct() {
      this.newsLoading = true;
      api.getCompanyNewsByProduct(this.companyNewsParams).then((res) => {
        console.log("获取产业舆情(按产品)", res);
        // this.newsList = []
        this.newsList = res.data;
        this.news_total = res.count;
        this.newsLoading = false;
      });
    },
    // 获取产业舆情
    getCompanyNews() {
      console.log("-----------------------获取产业舆情");
      this.newsLoading = true;
      // this.companys = []
      api
        .getCompanyNews(this.companyNewsParams)
        .then((res) => {
          // if (this.newsList.length !=0 && res.data[0].title == this.newsList[0].title) {
          //   return
          // }
          //此处加载慢
          console.log("产业舆情", res);
          this.newsList = res.data;
          this.news_total = res.count;
          console.log("loading stop");
          this.newsLoading = false;
        })
        .catch((err) => {
          this.newsLoading = false;
          console.error(err);
        });
      // this.newsLoading = false;
    },
    // 切换【最新舆情/热点事件】
    handleRadioChange(val) {
      // this.newsLoading = ture;
      this.style_height = "auto";
      if (val == "最新舆情") {
        this.getNewsCompany();
        this.showMoreCheckbox();
        this.companyNewsParams.page = 1;
        this.getCompanyNews();
      }
      if (val == "热点事件") {
        this.hotNewsParams.page = 1;
        this.getHotNews();
        // this.hotIndustryCompany = [];
        // this.companys.forEach((c) => {
        //   this.hotCompany.forEach((h) => {
        //     if (c.company_id == h.company_id) {
        //       this.hotIndustryCompany.push({
        //         label: c.value,
        //         value: c.value,
        //         company_id: h.company_id,
        //       });
        //     }
        //   });
        // });
        // console.log("companys", this.companys);
        // console.log("hotCompany", this.hotCompany);
        // console.log("切换【最新舆情/热点事件】", this.hotIndustryCompany);
        // let tmp = this.apiDataScatterChart;
        // this.apiDataScatterChart = [];
        // this.$nextTick(() => {
        //   this.apiDataScatterChart = tmp;
        // });
      }
    },
    // 获取新闻产业
    getNewsIndustry() {
      let params = {
        permissions: this.newsParams.permissions,
      };
      api.getNewsIndustry(params).then((res) => {
        console.log("新闻产业", res);
        this.imgList = res.data;
      });
    },
    // 点击产业
    handleClickIndustry(text) {
      console.log(text);
      this.newsParams.industry = text;
      this.filter_type = "全部";
      this.isFirst = false;
      // this.getAllData();
      this.getCompanyNews();
      // this.getCompanyNewsByProduct()
    },
    handleTabClick(val) {
      console.log(val);
      console.log("company_id", this.company_id);
      if (val.name == "2") {
        this.date = "";
        this.getNewsHotCountIndustry();
        if (this.company_id == "") {
          this.date = this.getToday();
          console.log("date", this.date);
        }
        this.getNewsKeywordIndustry();
      } else if (val.name == "1") {
        this.getNewsTypeCountIndustry();
        this.getNewsPolarityCountIndustry();
      }
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // 获取新闻关键词
    getNewsKeyword() {
      this.keywordLoading = true;
      let params = {
        // permissions: sessionStorage.getItem("permissions"),
        permissions: cookie.getCookie("permissions"),
        company_id: this.company_id,
        date: this.date,
      };
      api.getNewsKeyword(params).then((res) => {
        console.log(res);
        this.wordCloudData = res.data;
        this.keywordLoading = false;
      });
    },
    // 获取新闻关键词(产业舆情)
    getNewsKeywordIndustry() {
      this.keywordLoading = true;
      let params = {
        // permissions: sessionStorage.getItem("permissions"),
        date: this.date,
        industry: this.newsParams.industry,
      };
      api.getNewsKeywordIndustry(params).then((res) => {
        console.log(res);
        this.wordCloudData = res.data;
        this.keywordLoading = false;
      });
    },
    // 获取新闻热度统计(产业舆情)
    getNewsHotCountIndustry() {
      this.hotLoading = true;
      let params = {
        industry: this.newsParams.industry,
      };
      api
        .getNewsHotCountIndustry(params)
        .then((res) => {
          console.log("热度统计", res);
          this.newsEchartsData = res.data;
          this.hotLoading = false;
        })
        .catch((err) => {
          this.hotLoading = false;
        });
    },
    // 监听月份变化
    listenMonth() {
      EventBus.$on("month", (data) => {
        console.log("监听月份");
        this.date = data;
        this.getNewsKeyword();
      });
    },
    // 监听源产业变化
    listenSourceIndustry() {
      EventBus.$on("choose_source_industry", (data) => {
        console.log("choose_source_industry", data);
        this.newsList = [];
        this.newsParams.industry = "";
        this.newsParams.company = "";
        this.newsParams.page = 1;
        this.newsParams.polarity = "";
        this.newsParams.news_type = "";
        this.handleClickIndustry(data);
      });
    },
    // 监听新闻筛选
    listenNewsFilter() {
      EventBus.$on("news_filter", (data) => {
        console.log("监听新闻筛选");
        this.newsList = [];
        this.newsParams.page = 1;
        this.newsParams.news_type = data;
        this.getNews();
        this.getNewsPolarityCountIndustry();
      });
    },
    // 监听新闻极性选择
    listenPolarity() {
      EventBus.$on("polarity", (data) => {
        console.log("监听极性");
        this.newsList = [];
        this.newsParams.page = 1;
        this.newsParams.polarity = data;
        this.getNews();
      });
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        // this.newsType = ""
        // this.newsParams.company = ""
        this.company_id = "";
        this.tabsValue = "1";
        console.log("监听重置");
        this.newsList = [];
        this.isFirst = true;
        this.newsParams.industry = "";
        this.newsParams.company = "";
        this.newsParams.page = 1;
        this.newsParams.polarity = "";
        this.newsParams.news_type = "";
        console.log("newsList", this.newsList);
        // this.getNews();
        // this.getNewsPolarityCount();
        // this.getNewsPolarityCountIndustry()
        // this.getNewsTypeCount()
      });
    },
    // 监听重置筛选
    listenResetSelect() {
      EventBus.$on("resetSelect", (data) => {
        // this.newsType = ""
        // this.newsParams.company = ""
        this.company_id = "";
        this.tabsValue = "1";
        console.log("监听重置筛选");
        this.newsList = [];
        // this.newsParams.company = ""
        this.newsParams.page = 1;
        this.newsParams.polarity = "";
        this.newsParams.news_type = "";
        this.getNews();
        this.getNewsPolarityCountIndustry();
        // this.getNewsTypeCountIndustry()
      });
    },
    // 监听删除
    listenRemove() {
      EventBus.$on("remove_news", (data) => {
        console.log("监听删除新闻");
        this.newsList = [];
        this.getNews();
      });
    },
    // 监听修改新闻极性
    listenChangePolarity() {
      EventBus.$on("change_polarity", (data) => {
        console.log("监听修改新闻极性");
        this.newsList = [];
        this.getNews();
      });
    },
    // 监听产业链点击
    listenIndustryDrop() {
      EventBus.$on("industry_drop", (data) => {
        console.log("industry_drop", data)
        this.current_node_name = data.name;
        this.domain_id_select = data.id ? data.id : data.domain_id;
        this.companyNewsParams.page = 1;
        this.companyNewsParams.company = "";
        this.companyNewsParams.industry = "";
        this.companyNewsParams.news_type = "";
        this.companyNewsParams.domain_id = data.id
          ? data.id
          : data.domain_id;
        this.hotNewsParams.page = 1;
        this.hotNewsParams.industry = "";
        this.hotNewsParams.node_id = data.id
          ? data.id
          : data.domain_id;
        this.hotNewsParams.company = "";
        if (this.tabRadio == "最新舆情") {
          this.newsList = [];
          this.filter_type = "全部";
          this.companyCheckBox = [];
          this.$refs["checkbox-company"].style.height = "30px";
          this.style_height = "30px";
          this.companys = [];
          this.getNewsCompany();

          if (this.companyNewsParams.news_type != "司法公告") {
            this.getCompanyNews();
          } else {
            this.getEventRisk();
          }
        } else if (this.tabRadio == "热点事件") {
          // this.hotNewsLoading = true
          this.newsListHot = [];
          this.getHotNews();
        }
      });
    },
    // 监听树节点点击
    listenTreeClick() {
      EventBus.$on("tree_click", async (data) => {
        this.loadFinish = true;
        console.log("tree_click", data);
        this.current_node_name = data.name;
        this.companyNewsParams.page = 1;
        this.companyNewsParams.company = "";
        this.companyNewsParams.industry = "";
        this.companyNewsParams.news_type = "";
        this.companyNewsParams.domain_id = data.node_id;
        this.hotNewsParams.page = 1;
        this.hotNewsParams.industry = "";
        this.hotNewsParams.node_id = data.node_id;
        this.hotNewsParams.company = "";
        console.log("监听树节点点击");
        if (this.tabRadio == "最新舆情") {
          this.newsList = [];
          this.filter_type = "全部";
          this.companyCheckBox = [];
          this.$refs["checkbox-company"].style.height = "30px";
          this.style_height = "30px";
          this.companys = [];
          this.getNewsCompany();

          if (this.companyNewsParams.news_type != "司法公告") {
            this.getCompanyNews();
          } else {
            this.getEventRisk();
          }
        } else if (this.tabRadio == "热点事件") {
          // this.hotNewsLoading = true
          this.newsListHot = [];
          this.getHotNews();
        }
      });
    },
    // 获取新闻极性统计(产业标签)
    getNewsPolarityCountIndustry() {
      let params = {
        permissions: cookie.getCookie("permissions"),
        keyword: "",
        industry: this.newsParams.industry,
        news_type: this.newsParams.news_type,
      };
      this.polarityLoading = true;
      api.getNewsPolarityCountIndustry(params).then((res) => {
        console.log("极性", res);
        this.polarityData = res.data;
        this.polarityLoading = false;
      });
    },
    // 换页
    handlePageChangeNews(val) {
      console.log("换页");
      this.newsParams.page = val;
      this.getNews();
    },
    // 加载更多
    showMore() {
      this.companyNewsParams.page += 1;
      console.log("page", this.companyNewsParams.page);
      if (this.companyNewsParams.page <= 100) {
        // this.getNews();
        if (this.filter_type == "全部") {
          this.getCompanyNews();
        } else {
          this.getCompanyNewsByProduct();
        }
      }
      // else {
      //   this.showMoreText = "已无更多";
      // }
    },
    // 获取新闻
    getNews() {
      // this.loading = true;
      console.log("获取新闻");
      this.newsLoading = true;
      api.getNews(this.newsParams).then((res) => {
        console.log("news", res);
        // this.newsList = res.data;
        res.data.forEach((i) => {
          i.title_classify_tags.forEach((t, index) => {
            if (
              index > 0 &&
              index % 2 == 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 2]
            ) {
              i.title_classify_tags[index] = "";
            }
            if (
              index % 2 != 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 1]
            ) {
              i.title_classify_tags[index] = "";
            }
          });
          this.newsList.push(i);
        });
        // this.loading = false;
        this.newsLoading = false;
        console.log("获取新闻完毕1");
      });
    },
    // 获取新闻分类统计(产业标签)
    getNewsTypeCountIndustry() {
      let params = {
        permissions: cookie.getCookie("permissions"),
        company_id: "",
        keyword: "",
        industry: this.newsParams.industry,
      };
      this.newsTypeData.legend = [];
      this.newsTypeData.data = [];
      this.typeLoading = true;
      api.getNewsTypeCountIndustry(params).then((res) => {
        console.log("五大类", res);
        this.newsTypeData.legend = [];
        this.newsTypeData.data = [];
        res.data.forEach((i) => {
          this.newsTypeData.legend.push(i.name);
          this.newsTypeData.data.push(i.value);
        });
        console.log("xx", this.newsTypeData);
        this.typeLoading = false;
      });
    },
    // 获取当前年月
    getToday() {
      let myDate = new Date();
      let date = myDate.toLocaleDateString().split("/");
      // console.log("date", date)
      return `${date[0]}-${this.prefixZero(date[1], 2)}`;
    },
    // 前补0
    prefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    // 获取包含热点事件的公司
    getHotEventCompany() {
      api.getHotEventCompany().then((res) => {
        console.log("获取包含热点事件的公司", res);
        this.hotCompany = res.data;
      });
    },
    // 获取产业舆情公司
    getNewsCompany() {
      let params = {
        domain_id: this.companyNewsParams.domain_id,
        industry: "",
      };
      this.companys = [];
      this.hotIndustryCompany = [];
      api.getNewsCompany(params).then((res) => {
        console.log("获取产业舆情公司", res);
        // this.companys.push({
        //   label: "全部",
        //   value: ""
        // })
        this.companys = res.data.map((i) => {
          return {
            label: i.short_name,
            value: i.short_name,
            company_id: i.company_id,
          };
        });
        this.companys.unshift({
          label: "全部",
          value: "",
        });
        res.data.forEach((i) => {
          // console.log("获取产业舆情公司 iii", i)
          // this.companys.push({
          //   company_id: i.company_id,
          //   short_name: i.short_name,
          //   full_name: i.full_name
          // })
          // if (this.companys.indexOf(i.short_name) == -1) {
          //   this.companys.push(i.short_name)
          // }
          // this.companys.push({
          //   label: i.short_name,
          //   value: i.short_name,
          //   company_id: i.company_id
          // })
          this.hotCompany.forEach((c) => {
            if (i.company_id == c.company_id) {
              console.log("---", i);
              this.hotIndustryCompany.push({
                label: i.short_name,
                value: i.short_name,
                company_id: c.company_id,
              });
            }
          });
          this.hotEventCompany = this.hotIndustryCompany[0].label;
          // this.companys.forEach(c => {
          //   if (c.label != i.short_name) {
          //     this.companys.push({
          //       label: i.short_name,
          //       value: i.short_name
          //     })
          //   }
          // })
        });
        this.getHotCompanyByInfo(this.hotIndustryCompany[0]);
        console.log("-----companys-----", this.companys);
        console.log("hotIndustryCompany", this.hotIndustryCompany);
      });
      // return
    },
    // 热点事件企业选择
    // handleRadioHotCompany(val) {
    //   console.log("热点事件企业选择", val)
    //   this.hotEventParams.company_id = val.company_id
    //   this.getHotEvent()
    // },
    // 根据公司信息获取热点事件
    getHotCompanyByInfo(val) {
      console.log("获取热点事件", val);
      this.hotEventParams.company_id = val.company_id;
      this.getHotEvent();
    },
    // 热点事件企业选择
    handleRadioHotCompany(val) {
      console.log("热点事件企业选择", val);
      // this.hotEventParams.company_id = val.company_id
      // this.getHotEvent()
      this.hotIndustryCompany.forEach((i) => {
        if (i.value == val) {
          this.hotEventParams.company_id = i.company_id;
          this.getHotEvent();
        }
      });
    },
    showMoreCheckbox() {
      console.log("style_height", this.style_height);
      if (this.style_height == "30px") {
        this.$refs["checkbox-company"].style.height = "auto";
        this.style_height = "auto";
      } else if (this.style_height == "auto") {
        this.$refs["checkbox-company"].style.height = "30px";
        this.style_height = "30px";
      }
      console.log("style_height", this.style_height);
    },
  },
  mounted() {
    this.subscribeNodeName = JSON.parse(
      sessionStorage.getItem("subscribeNodeName")
    );
    console.log("loadFinish", this.loadFinish);
    // this.newsShowLine = utils.randomNum()
    console.log("newsShowLine", this.newsShowLine);
    // this.newsParams.permissions = sessionStorage.getItem("permissions");
    this.env = process.env.VUE_APP_CURRENTMODE;

    //首次进加载
    if (!this.loadFinish) {
      console.log("==========getHotEvent===========");
      this.getHotNews();
    }

    this.getHotEventCompany();

    this.newsParams.permissions = cookie.getCookie("permissions");
    cookie.setCookie("serverValue", "产业舆情");
    // this.date = this.getToday();
    this.date = "";
    let index_industry = JSON.parse(sessionStorage.getItem("index_industry"));
    // if (sessionStorage.getItem("index_industry"))
    if (index_industry.name == "动力锂电池系统") {
      index_industry.name = "新能源汽车";
    }
    this.companyNewsParams.account = this.hotNewsParams.account =
      cookie.getCookie("account");
    this.companyNewsParams.domain_id = index_industry.domain_id;
    // this.getNewsCompany();
    // setTimeout(() => {
    //   if (this.newsList.length == 0) {
    //     this.getCompanyNews();
    //   }
    // }, 1500);

    // this.getCompanyNews()
    // this.getHotNews()
    // this.handleClickIndustry(index_industry.name);
    // this.getAllData()
    this.getNewsIndustry();
    // this.listenReset();
    this.listenNewsFilter();
    this.listenPolarity();
    this.listenSourceIndustry();
    this.listenRemove();
    this.listenMonth();
    this.listenChangePolarity();
    this.listenResetSelect();
    this.listenTreeClick();
    this.listenIndustryDrop();
    this.listenUpdateSubscribe()
  },
  beforeDestroy() {
    EventBus.$off("news_filter");
    EventBus.$off("polarity");
    // EventBus.$off("reset");
    EventBus.$off("resetSelect");
    EventBus.$off("month");
    EventBus.$off("remove_news");
    EventBus.$off("change_polarity");
    EventBus.$off("choose_source_industry");
    EventBus.$off("industry_drop");
    EventBus.$off("updateSubscribe")
  },
};
</script>

<style lang="scss">
.NewsListNewBox {
  display: flex;
  height: 100%;
  .NewsListNewLeftBox {
    flex: 2;
    // margin-top:20px !important;
  }
  .NewsListNewRightBox {
    flex: 1;
    padding-right: 10px;
  }
}

.NewsListHotBox {
  display: flex;
  flex-direction: row;
  .NewsListHotLeftBox {
    flex: 2;
    // margin-top:20px !important;
  }
  .NewsListHotRightBox {
    flex: 1;
    padding-right: 10px;
  }
}

.industryNews {
  // margin-top: 52px;
  width: 100%;
  min-height: 85vh;
  // padding-bottom: 10px;
  &-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0 0 15px;
    color: #333333;
  }
  .el-form-item__label {
    color: #999;
    margin-left: 10px;
  }
  .el-form-item {
    margin-bottom: 15px !important;
  }
  .el-form-item__label,
  .el-form-item__content {
    line-height: 25px;
  }

  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
    position: relative;
  }
  &-right {
    margin-left: 5px;
  }
  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }
  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .newsEchartsBox {
    background-color: white;
    display: flex;
    min-height: 250px;
    width: 100%;
    margin-top: 4px;
    border-radius: 6px;
  }
  .checkbox {
    &-down {
      // position: absolute;
      // right: 2vw;
      // top: 40px;
      // font-size: 24px;
      margin-left: 15px;
      margin-right: 15px;
      z-index: 99;
      cursor: pointer;
    }
    &-company {
      display: flex;
      height: 30px;
      overflow: hidden;
    }
  }
}
</style>
